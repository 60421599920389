import React, { type FC } from 'react';

const NavMenu: FC = () => {
    return (
        <div className="w-full flex flex-col pt-8 text-base-content">
            <div className="text-base pb-2">Menu</div>
            <ul className="ml-6">
                <li>
                    <div className="pb-2">
                        <a href="/accounts">Accounts</a>
                    </div>
                    <ul className="ml-6">
                        <li className="pb-2">
                            <a href="/accounts/superadmins">S Admins</a>
                        </li>
                        <li className="pb-2">
                            <a href="/accounts/admins">Admins</a>
                        </li>
                        <li className="pb-2">
                            <a href="/accounts/teachers">Coaches/TC/FSI</a>
                        </li>
                        <li className="pb-2">
                            <a href="/accounts/consultants">Consultants</a>
                        </li>
                        <li className="pb-2">
                            <a href="/accounts/users">Users</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <div className="pb-2">Reports</div>
                    <ul className="ml-6">
                        <li className="pb-2">
                            <a href="/sales">Sales</a>
                        </li>
                        <li className="pb-2">
                            <a href="/stats">Stats</a>
                        </li>
                        <li className="pb-2">
                            <a href="/admin-notes">Admin Notes</a>
                        </li>
                        <li className="pb-2">
                            <a href="/chart-reviews">Chart Reviews</a>
                        </li>
                        <li className="pb-2">
                            <a href="/audit">Audit</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <div className="pb-2">
                        <a href="/promo-codes">Promo Codes</a>
                    </div>
                    <ul className="ml-6">
                        <li className="pb-2">
                            <a href="/promo-codes/valid">Valid Only</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default NavMenu;
