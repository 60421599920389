import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Accounts from '../pages/Accounts';
import Sales from '../pages/Sales';
import PromoCodes from '../pages/PromoCodes';
import Stats from '../pages/Stats';
import AdminNotes from '../pages/AdminNotes';
import Audit from '../pages/Audit';
import ChartReviews from '../pages/ChartReviews';

const mainRouter = createBrowserRouter([
    {
        path: '/',
        errorElement: (
            <div className="text-primary text-3xl text-center">
                404 Not Found
            </div>
        ),
        element: <Accounts />,
    },
    {
        path: 'accounts',
        children: [
            {
                index: true,
                element: <Accounts />,
            },
            {
                path: 'superadmins',
                element: <Accounts userType="superadmin" />,
            },
            {
                path: 'admins',
                element: <Accounts userType="admin" />,
            },
            {
                path: 'teachers',
                element: <Accounts userType="teacher" />,
            },
            {
                path: 'consultants',
                element: <Accounts userType="medical" />,
            },
            {
                path: 'users',
                element: <Accounts userType="user" />,
            },
        ],
    },
    {
        path: 'sales',
        element: <Sales />,
    },
    {
        path: 'stats',
        element: <Stats />,
    },
    {
        path: 'admin-notes',
        element: <AdminNotes />,
    },
    {
        path: 'chart-reviews',
        element: <ChartReviews />,
    },
    {
        path: 'audit',
        element: <Audit />,
    },
    {
        path: 'promo-codes',
        children: [
            {
                index: true,
                element: <PromoCodes />,
            },
            {
                path: 'valid',
                element: <PromoCodes validOnly />,
            },
        ],
    },
]);

export default mainRouter;
