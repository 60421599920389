import React, { useState, type FC, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import Table from '../components/Table';
import type UserTypeModel from '../models/UserTypeModel';
import type DbDataModel from '../models/DbDataModel';
import api from '../services/api';
import LoadingView from '../components/LoadingView';
import type UserModel from '../models/UserModel';
import RoleManagementTab from '../components/RoleManagementTab';
import AdminNotesTab from '../components/AdminNotesTab';
import PaymentHistoryTab from '../components/PaymentHistoryTab';
import { capitalizeFirstLetter } from '../utils/general';
import AccountConnectTab from '../components/AccountConnectTab';
import NewUserPanel from '../components/NewUserPanel';
import { useIsSuperAdmin } from '../redux';

const Accounts: FC<{ userType?: UserTypeModel }> = ({ userType }) => {
    const [data, setData] = useState<DbDataModel[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [showNewUserPopup, setShowNewUserPopup] = useState(false);
    const [row, setRow] = useState<UserModel | undefined>();

    const toast = useRef<Toast>(null);
    const isSuperAdmin = useIsSuperAdmin();

    const updateData = async () => {
        setLoading(true);
        const { users, message, success } = await api.getUsers(userType);
        if (success) {
            setData(users);
            setErrorMessage('');
        } else {
            setData([]);
            setErrorMessage(message);
        }

        setLoading(false);
    };

    useEffect(() => {
        void updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType]);

    const columns = [
        'id',
        'first_name',
        'last_name',
        'username',
        'email',
        'phone_number',
        'language',
        'join_date',
        'sub_expires',
        'subscription_level',
        'last_login_date',
        'num_of_logins',
        'last_entry',
        'app_version',
        'referral_source',
        'privilege',
        'role',
        'terra_device',
        'method_chart',
    ];
    const frozenColumns = ['id', 'first_name', 'last_name'];

    return (
        <>
            <Toast ref={toast} position="top-right" />
            <div className="text-primary text-3xl pb-4">Accounts</div>
            {loading && <LoadingView />}
            {!loading && errorMessage && (
                <div className="text-primary text-xl">
                    Error: {errorMessage}
                </div>
            )}
            {!loading && !errorMessage && (
                <>
                    {isSuperAdmin && (
                        <button
                            type="button"
                            className="btn btn-primary mb-4"
                            onClick={() => {
                                setShowNewUserPopup(true);
                            }}
                        >
                            Add User
                        </button>
                    )}
                    <Table
                        title={`${
                            userType ? capitalizeFirstLetter(userType) : 'All'
                        } Accounts`}
                        data={data}
                        columns={columns}
                        frozenColumns={frozenColumns}
                        onClick={(row) => {
                            setRow(row as UserModel);
                            setShowPopup(true);
                        }}
                    />
                </>
            )}
            <Dialog
                visible={showPopup}
                header={row?.username}
                onHide={() => {
                    setShowPopup(false);
                }}
            >
                <TabView>
                    <TabPanel header="Role Management">
                        <RoleManagementTab
                            user={row}
                            setShowPopup={setShowPopup}
                            toast={toast}
                        />
                    </TabPanel>
                    <TabPanel header="Administrative Notes">
                        <AdminNotesTab
                            user={row}
                            setShowPopup={setShowPopup}
                            toast={toast}
                        />
                    </TabPanel>
                    <TabPanel header="Account Connect">
                        <AccountConnectTab user={row} toast={toast} />
                    </TabPanel>
                    <TabPanel header="Payment History">
                        <PaymentHistoryTab user={row} />
                    </TabPanel>
                </TabView>
            </Dialog>
            <Dialog
                visible={showNewUserPopup}
                header="Add User"
                onHide={() => {
                    setShowNewUserPopup(false);
                }}
            >
                <NewUserPanel
                    setShowPopup={setShowNewUserPopup}
                    toast={toast}
                />
            </Dialog>
        </>
    );
};

export default Accounts;
