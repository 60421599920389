import login from './endpoints/login';
import getUsers from './endpoints/getUsers';
import setPermissions from './endpoints/setPermissions';
import getPayments from './endpoints/getPayments';
import getPromoCodes from './endpoints/getPromoCodes';
import createPromoCode from './endpoints/createPromoCode';
import deletePromoCode from './endpoints/deletePromoCode';
import getUsageStatistics from './endpoints/getUsageStatistics';
import resetPassword from './endpoints/resetPassword';
import anonymizeUser from './endpoints/anonymizeUser';
import setUserInfo from './endpoints/updateUserInfo';
import getAdminNotes from './endpoints/getAdminNotes';
import getAllAdminNotes from './endpoints/getAllAdminNotes';
import addAdminNote from './endpoints/addAdminNote';
import getUserPayments from './endpoints/getUserPayments';
import getAccountConnectInfo from './endpoints/getAccountConnectInfo';
import connectAccount from './endpoints/connectAccount';
import disconnectAccount from './endpoints/disconnectAccount';
import setExpirationDate from './endpoints/setExpirationDate';
import getAuditReport from './endpoints/getAuditReport';
import deleteTerraDevice from './endpoints/deleteTerraDevice';
import getChartReviews from './endpoints/getChartReviews';
import addUser from './endpoints/addUser';

const api = {
    login,
    getUsers,
    setPermissions,
    getPayments,
    getPromoCodes,
    createPromoCode,
    deletePromoCode,
    getUsageStatistics,
    resetPassword,
    anonymizeUser,
    setUserInfo,
    getAdminNotes,
    getAllAdminNotes,
    addAdminNote,
    getUserPayments,
    getAccountConnectInfo,
    connectAccount,
    disconnectAccount,
    setExpirationDate,
    getAuditReport,
    deleteTerraDevice,
    getChartReviews,
    addUser,
};

export default api;
