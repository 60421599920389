import { requestAndParse, requestSuccessful, serverError } from '../request';

const login = async (username: string, password: string) => {
    const route = 'auth/admin-login';
    const payload = {
        username,
        password,
    };

    const response = await requestAndParse(route, payload);

    const success = requestSuccessful(response);
    const token = response.body?.token as string;
    const message = response.body?.message as string;
    const isSuperAdmin = (response.body?.isSuperAdmin ?? false) as boolean;

    const error = serverError(response);

    return { success, token, error, message, isSuperAdmin };
};

export default login;
