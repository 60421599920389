import React, { type FC } from 'react';
import { type Toast } from 'primereact/toast';
import { type FieldValues, useForm } from 'react-hook-form';
import { updatePermissions, validatePassword } from '../helpers/AccountHelpers';
import api from '../services/api';
import { type Privilege, type Role } from '../models/UserModel';

export type NewUserData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    language: 'English' | 'Spanish';
    birthYear: string;
    privilege: Privilege;
    role: Role;
};

const NewUserPanel: FC<{
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    toast: React.RefObject<Toast>;
}> = ({ setShowPopup, toast }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data: FieldValues) => {
        const userData = data as NewUserData;

        const { success, message } = await api.addUser(
            userData.firstName,
            userData.lastName,
            userData.email,
            userData.password,
            userData.language === 'English' ? 'en' : 'es',
            Number(userData.birthYear),
        );

        if (success) {
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'User Created',
            });
        } else {
            toast.current?.show({
                severity: 'warn',
                summary: 'User Creation Failed',
                detail: message ?? 'unknown error',
            });
        }

        if (success) {
            if (
                userData.privilege !== 'Non-Admin' ||
                userData.role !== 'User'
            ) {
                const { users } = await api.getUsers(
                    undefined,
                    undefined,
                    undefined,
                    userData.email,
                );
                await updatePermissions(userData, users[0], toast);
            }

            setShowPopup(false);
        }
    };

    const Row: FC<{
        label: string;
        id: string;
        defaultValue?: string;
        required?: boolean;
        validate?: (value: string) => boolean;
        validateError?: string;
        type?: React.HTMLInputTypeAttribute;
    }> = ({
        label,
        id,
        defaultValue,
        required,
        validate,
        validateError,
        type,
    }) => {
        return (
            <>
                <div className="flex items-center">
                    <label htmlFor={id}>{label}</label>
                </div>
                <div className="flex flex-col col-span-3">
                    <input
                        type={type}
                        id={id}
                        defaultValue={defaultValue}
                        className="border border-black rounded text-primary w-full p-1"
                        {...register(id, { required, validate })}
                    />
                    {errors[id]?.type === 'required' && (
                        <span className="text-red-500">
                            This field is required
                        </span>
                    )}
                    {errors[id]?.type === 'validate' && (
                        <span className="text-red-500">{validateError}</span>
                    )}
                </div>
            </>
        );
    };

    const DropdownRow: FC<{
        label: string;
        id: string;
        options: string[];
        defaultValue?: string;
    }> = ({ label, id, options, defaultValue }) => {
        return (
            <>
                <div className="flex items-center">
                    <label htmlFor={id}>{label}</label>
                </div>
                <div className="col-span-3">
                    <select
                        id={id}
                        className="border border-black rounded text-primary w-full p-1"
                        defaultValue={defaultValue}
                        {...register(id, { required: true })}
                    >
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        );
    };

    return (
        <div>
            <form
                className="flex flex-col items-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="grid grid-cols-4 gap-4 w-[600px]">
                    <Row required label="First Name" id="firstName" />
                    <Row required label="Last Name" id="lastName" />
                    <Row required label="Email Address" id="email" />
                    <Row
                        label="Password"
                        id="password"
                        validate={validatePassword}
                        validateError="Password must be at least 8 characters and contain one lower case letter, one upper case letter, and one number."
                    />
                    <DropdownRow
                        label="Language"
                        id="language"
                        options={['English', 'Spanish']}
                        defaultValue="English"
                    />
                    <Row
                        required
                        label="Birth Year"
                        id="birthYear"
                        type="number"
                        defaultValue="2000"
                    />
                    <DropdownRow
                        label="Privilege"
                        id="privilege"
                        defaultValue="Non-Admin"
                        options={['Super Admin', 'Admin', 'Non-Admin']}
                    />
                    <DropdownRow
                        label="Role"
                        id="role"
                        defaultValue="User"
                        options={['User', 'Consultant', 'TC', 'Coach', 'FSI']}
                    />
                </div>
                <input type="submit" className="btn btn-primary mt-4" />
            </form>
        </div>
    );
};

export default NewUserPanel;
