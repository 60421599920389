import { requestAndParse, requestSuccessful } from '../request';

const addUser = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    language: 'en' | 'es',
    birthYear: number,
    // eslint-disable-next-line max-params
) => {
    const route = 'auth/signup';
    const payload = {
        firstName,
        lastName,
        email,
        password,
        language,
        birthYear,
        typicalPeriodInterval: 30,
        typicalPeriodLength: 7,
        emailSignup: false,
    };

    const response = await requestAndParse(route, payload);

    const success = requestSuccessful(response);
    const message = response.body?.message as string;

    return { success, message };
};

export default addUser;
