import type UserModel from '../../../models/UserModel';
import type UserTypeModel from '../../../models/UserTypeModel';
import { requestAndParse, requestSuccessful } from '../request';

const getUsers = async (
    userType?: UserTypeModel,
    searchTerm?: string,
    limit?: number,
    username?: string,
) => {
    const route = 'admin/get-users';
    const payload = {
        userType,
        searchTerm,
        limit,
        username,
    };

    const response = await requestAndParse(route, payload, 'GET');

    const success = requestSuccessful(response);
    const users = (response.body?.users ?? []) as UserModel[];
    const message = response.body?.message as string;

    return { success, users, message };
};

export default getUsers;
